import {
  Box,
  Heading,
  Stack,
  Text,
  UnorderedList,
  ListItem,
  Link,
} from "@chakra-ui/react";
import React from "react";
import Layout from "../components/Layout/Layout";
import Seo from "../components/Seo/Seo";

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <Seo />

      <Box
        pb="144px"
        pt={{ base: "120px", lg: "204px" }}
        overflow="hidden"
        width="100%"
        gridColumn="1/-1"
        position="relative"
      >
        <Stack
          opacity="1"
          display="flex"
          alignItems="start"
          flex="0 1 0%"
          maxWidth={{ base: "unset", xl: "1206px" }}
          px={{ base: "6", md: "10", lg: "20", "3xl": "0" }}
          margin="0px auto"
          spacing="2"
        >
          <p class="c11">
            <Heading as="h1" fontSize={{ base: "28px", md: "36px" }}>
              PRIVACY NOTICE
            </Heading>
          </p>
          <p class="c11 c5">
            <span class="c6"></span>
          </p>
          <p class="c11">
            <span class="c8 c27">Last updated September 19, 2022</span>
          </p>
          <p class="c11 c5">
            <span class="c6"></span>
          </p>
          <p class="c7">
            <span class="c18">
              Thank you for choosing to be part of our community at Motarme
              Limited (&quot;
            </span>
            <span class="c0">Company</span>
            <span class="c18">,&quot; &quot;</span>
            <span class="c0">we</span>
            <span class="c18">,&quot; &quot;</span>
            <span class="c0">us</span>
            <span class="c18">,&quot; or &quot;</span>
            <span class="c0">our</span>
            <span class="c18">
              &quot;). We are committed to protecting your personal information
              and your right to privacy. If you have any questions or concerns
              about this privacy notice or our practices with regarding your
              personal information, please contact us at{" "}
            </span>
            <span class="c17">
              <Link
                style={{ color: "blue" }}
                _hover={{
                  textDecoration: "underline",
                }}
                href="mailto:privacy@motarme.com"
              >
                privacy@motarme.com
              </Link>
            </span>
            <span class="c9">.</span>
          </p>
          <p class="c7 c5">
            <span class="c9"></span>
          </p>
          <p class="c21 c7">
            <span class="c18 c36">
              Motar.me enhances email and workflows. It adds features to Gmail
              including email tracking, scheduling, reminders, mail merge
              campaigns, and other capabilities. It is often used by
              professionals in people-facing roles, such as Sales, Customer
              Support, Account Managers, etc.
            </span>
          </p>
          <p class="c7">
            <span class="c27 c33 c26">
              This privacy notice describes how we might use your information if
              you:
            </span>
          </p>
          <UnorderedList pl="6" class="c2 lst-kix_list_46-0 start">
            <ListItem class="c16 li-bullet-0">
              <span class="c18">Visit our website at </span>
              <span class="c17">
                <Link
                  style={{ color: "blue" }}
                  _hover={{
                    textDecoration: "underline",
                  }}
                  href="https://motar.me/"
                >
                  https://motar.me/
                </Link>
              </span>
            </ListItem>
            <ListItem class="c7 c20 li-bullet-0">
              <span class="c9">
                Sign up to and use our application —{" "}
                <Link
                  style={{ color: "blue" }}
                  _hover={{
                    textDecoration: "underline",
                  }}
                  href="https://app.motar.me/"
                >
                  https://app.motar.me/
                </Link>
              </span>
            </ListItem>
            <ListItem class="c29 c20 li-bullet-0">
              <span class="c9">
                Engage with us in other related ways &#8213; including any
                sales, marketing, or events
              </span>
            </ListItem>
          </UnorderedList>
          <p class="c7">
            <span class="c27 c33 c26">
              In this privacy notice, if we refer to:
            </span>
          </p>
          <UnorderedList pl="6" class="c2 lst-kix_list_34-0 start">
            <ListItem class="c16 li-bullet-0">
              <span class="c18">&quot;</span>
              <span class="c0">Website</span>
              <span class="c9">
                ,&quot; we are referring to any website of ours that references
                or links to this policy
              </span>
            </ListItem>
            <ListItem class="c7 c20 li-bullet-0">
              <span class="c18">&quot;</span>
              <span class="c0">App</span>
              <span class="c9">
                ,&quot; we are referring to any application of ours that
                references or links to this policy, including any listed above
              </span>
            </ListItem>
            <ListItem class="c29 c20 li-bullet-0">
              <span class="c18">&quot;</span>
              <span class="c0">Services</span>
              <span class="c9">
                ,&quot; we are referring to our Website, App, and other related
                services, including any sales, marketing, or events.
              </span>
            </ListItem>
          </UnorderedList>
          <p class="c7">
            <span class="c22 c31">
              The purpose of this privacy notice is to explain to you what
              information we collect, how we use it, and what rights you have in
              relation to it. If there are any terms in this privacy notice that
              you do not agree with, please discontinue use of our Services
              immediately.
            </span>
          </p>
          <p class="c7 c5">
            <span class="c22 c31"></span>
          </p>
          <p class="c7">
            <span class="c34">
              By using or accessing the Website, App or Services, and/or
              communicating with a Motarme via mail, email, or telephone you are
              accepting the practices described in this Privacy Policy.
            </span>
          </p>
          <p class="c7 c5">
            <span class="c6"></span>
          </p>
          <p class="c7 c5">
            <span class="c6"></span>
          </p>
          <Stack>
            <p class="c7">
              <Heading as="h2" fontSize={{ base: "20px", md: "24px" }} mt="8">
                Collecting personal data
              </Heading>
            </p>

            <Text fontWeight="bold">
              Personal information you disclose to us
            </Text>

            <p class="c7">
              <span class="c0 c12">In Short: &nbsp;</span>
              <span class="c22 c18 c12">
                We collect personal information that you voluntarily provide to
                us when you register on the Services, express an interest in
                obtaining information about us or our products and Services,
                when you participate in activities on the Services or when you
                contact us.
              </span>
            </p>

            <p class="c7 c5">
              <span class="c6"></span>
            </p>

            <p class="c7 c5">
              <span class="c6"></span>
            </p>

            <p class="c7">
              <span class="c9">
                The personal information we collect may include the following:
              </span>
            </p>

            <UnorderedList pl="6" class="c2 lst-kix_list_23-0 start">
              <ListItem class="c16 li-bullet-0">
                Personal Information Provided by You. We collect names; email
                addresses; mailing addresses; job titles; usernames; and other
                similar information.
              </ListItem>
              <ListItem class="c16 li-bullet-0">
                Google Account login. We provide you with the option to register
                with us using your Google Account. When you choose to do this,
                we will receive certain profile information about you that you
                have made publicly available, which may include your name,
                e-mail address and profile picture.
              </ListItem>
              <ListItem class="c16 li-bullet-0">
                Gmail Inbox. If you agree, we may receive access to your inbox
                to send emails on your behalf and view the message ID and thread
                ID of bounced emails. We will not collect any information about
                your contact list or view the content of the emails.
              </ListItem>
            </UnorderedList>

            <p class="c7">
              <span class="c9">
                We may also collect other personal information outside of these
                categories or instances when you interact with us in-person,
                online, or by phone or mail in the context of:
              </span>
            </p>

            <UnorderedList pl="6" class="c2 lst-kix_list_23-0 start">
              <ListItem class="c16 li-bullet-0">
                Receiving help through our customer support channels;
              </ListItem>
              <ListItem class="c16 li-bullet-0">
                Participation in customer surveys; and
              </ListItem>
              <ListItem class="c16 li-bullet-0">
                Responding to your inquiries.
              </ListItem>
            </UnorderedList>

            <p class="c7">
              <span class="c9">
                All personal information that you provide to us must be true,
                complete, and accurate, and you must notify us of any changes to
                such personal information.
              </span>
            </p>

            <Text fontWeight="bold">Information automatically collected</Text>
            <p class="c7">
              <span class="c9">
                Some information is collected automatically when you visit our
                Services. This information does not reveal your specific
                identity (like your name or contact information) but may include
                device and usage information. This information is primarily
                needed to maintain the security and operation of our Services,
                and for our internal analytics and reporting purposes.
              </span>
            </p>
            <p class="c7">
              <span class="c9">
                The information we collect includes log and usage data which is
                service-related, diagnostic, usage and performance information
                our servers automatically collect when you access or use our
                Services and which we record in log files. This log data may
                include your IP address, device information, browser type and
                settings and information about your activity in the Services
                (such as the date/time stamps associated with your usage, pages
                and files viewed, searches and other actions you take such as
                which features you use), device event information (such as
                system activity, error reports and hardware settings).
              </span>
            </p>

            <Text fontWeight="bold">How do we use your information?</Text>
            <p class="c7">
              <span class="c9">
                We use the information we collect or receive for the following
                purposes:
              </span>
            </p>

            <UnorderedList pl="6" class="c2 lst-kix_list_23-0 start">
              <ListItem class="c16 li-bullet-0">
                If you choose to link your account with us to a third-party
                account (such as your Google account), we use the information
                you allowed us to collect from those third parties to facilitate
                account creation and logon process for the performance of the
                contract.
              </ListItem>
              <ListItem class="c16 li-bullet-0">
                We occasionally post testimonials on our Services that may
                contain personal information. We only post a testimonial with
                your prior consent. Prior to posting a testimonial, we will
                obtain your consent to use your name and the content of the
                testimonial. If you then wish to update, or delete your
                testimonial, you can contact us at{" "}
                <Link
                  style={{ color: "blue" }}
                  _hover={{
                    textDecoration: "underline",
                  }}
                  href="mailto:privacy@motarme.com"
                >
                  privacy@motarme.com
                </Link>{" "}
                at any time and we will edit or remove as requested.
              </ListItem>
              <ListItem class="c16 li-bullet-0">
                We may use your information to request feedback and to contact
                you about your use of our Services.
              </ListItem>
              <ListItem class="c16 li-bullet-0">
                We may use your information to enable user-to-user
                communications with each user's consent.
              </ListItem>
              <ListItem class="c16 li-bullet-0">
                We may use your information for the purposes of managing our
                account and keeping it in working order.
              </ListItem>
              <ListItem class="c16 li-bullet-0">
                We may use your personal information to send you product,
                service and new feature information and/or information about
                changes to our terms, conditions, and policies.
              </ListItem>
              <ListItem class="c16 li-bullet-0">
                We may use your information as part of our efforts to keep our
                Services safe and secure.
              </ListItem>{" "}
              <ListItem class="c16 li-bullet-0">
                We may use the information to enforce our terms, conditions, and
                policies for business purposes, to comply with legal and
                regulatory requirements or in connection with our contract.
              </ListItem>{" "}
              <ListItem class="c16 li-bullet-0">
                If we receive a subpoena or other legal request, we may need to
                inspect the data we hold to determine how to respond.
              </ListItem>{" "}
              <ListItem class="c16 li-bullet-0">
                We may use your information to deliver and facilitate delivery
                of the requested service.
              </ListItem>{" "}
              <ListItem class="c16 li-bullet-0">
                We may use your information to respond to your inquiries and
                solve any potential issues you might have with the use of our
                Services.
              </ListItem>{" "}
              <ListItem class="c16 li-bullet-0">
                We may use the personal information you send to us for our
                marketing purposes if this is in accordance with your marketing
                preferences. We will ask you to opt-in to any marketing
                communications before sending them, And after opt-in you can
                unsubscribe from our marketing email list at any time by
                clicking on the unsubscribe link in the marketing emails that we
                send or by contacting us at privacy@motarme.com.
              </ListItem>{" "}
              <ListItem class="c16 li-bullet-0">
                We may use your information for other business purposes, such as
                data analysis, identifying usage trends, and to evaluate and
                improve our Services, products, marketing and your experience.
                We may use and store this information in aggregated and
                anonymized form so that it is not associated with individual end
                users and does not include personal information.
              </ListItem>
            </UnorderedList>

            <p class="c11 c5">
              <span class="c6"></span>
            </p>
            <p class="c7 c5">
              <span class="c6"></span>
            </p>

            <p class="c7">
              <Heading as="h2" fontSize={{ base: "20px", md: "24px" }}>
                Processing and sharing personal data
              </Heading>
            </p>
            <p class="c11 c5">
              <span class="c6"></span>
            </p>
            <p class="c7">
              <span class="c22 c18 c12">
                We may process or share your data that we hold based on the
                following legal basis:
              </span>
            </p>

            <UnorderedList pl="6" class="c2 lst-kix_list_23-0 start">
              <ListItem class="c16 li-bullet-0">
                Consent: We may process your data if you have given us specific
                consent to use your personal information for a specific purpose.
                If we are relying on your consent to process your personal
                information, you have the right to withdraw your consent at any
                time. This will not affect the lawfulness of the processing
                before its withdrawal, nor will it affect the processing of your
                personal information conducted in reliance on lawful processing
                grounds other than consent
              </ListItem>
              <ListItem class="c16 li-bullet-0">
                Legitimate Interests: We may process your data when it is
                reasonably necessary to achieve our legitimate business
                interests.
              </ListItem>
              <ListItem class="c16 li-bullet-0">
                Performance of a Contract: Where we have entered a contract with
                you, we may process your personal information to fulfil the
                terms of our contract.
              </ListItem>
              <ListItem class="c16 li-bullet-0">
                Legal Obligations: We may disclose your information where we are
                legally required to do so to comply with applicable law,
                governmental requests, a judicial proceeding, court order, or
                legal process, such as in response to a court order or a
                subpoena.
              </ListItem>
            </UnorderedList>

            <p class="c7 c5">
              <span class="c6"></span>
            </p>

            <p class="c7 c5">
              <span class="c6"></span>
            </p>
          </Stack>
          <p class="c7">
            <Heading as="h2" fontSize={{ base: "20px", md: "24px" }}>
              Limited Use Requirements
            </Heading>
          </p>
          <p class="c7 c5">
            <span class="c6"></span>
          </p>
          <p class="c7">
            <span class="c0 c12">In Short: &nbsp;</span>
            <span class="c22 c18 c12">
              Motarme’s use of information received from Google APIs complies
              with{" "}
              <Link
                style={{ color: "blue" }}
                _hover={{
                  textDecoration: "underline",
                }}
                href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
              >
                Google API Services User Data Policy{" "}
              </Link>{" "}
              and the Limited Use requirements.
            </span>
          </p>
          <p class="c7 c5">
            <span class="c6"></span>
          </p>
          <p class="c7">
            <span class="c9">
              Our app strictly complies with all conditions specified in
              Google’s limited use policy.
            </span>
          </p>
          <p class="c7 c5">
            <span class="c6"></span>
          </p>
          <UnorderedList pl="6" class="c2 lst-kix_list_36-0 start">
            <ListItem class="c16 li-bullet-0">
              We limit our use of data to providing or improving user-facing
              features that are prominent in our application’s user interface;
            </ListItem>

            <ListItem class="c7 c20 li-bullet-0">
              We do not use or transfer data for serving ads, including
              retargeting, personalized, or interest-based advertising;
            </ListItem>

            <ListItem class="c7 c20 li-bullet-0">
              We do not allow humans to read the user’s data unless we have
              obtained the user’s affirmative agreement to view specific
              messages, files, or other data.
            </ListItem>
          </UnorderedList>
          <p class="c7 c5">
            <span class="c6"></span>
          </p>{" "}
          <p class="c7 c5">
            <span class="c6"></span>
          </p>
          <Heading as="h2" fontSize={{ base: "20px", md: "24px" }} mt="8">
            The usage of cookies
          </Heading>
          <p class="c1">
            <span class="c22 c18 c12">
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to access or store information. Specific
              information about how we use such technologies and how you can
              refuse certain cookies is set out in our Cookie Notice.
            </span>
          </p>
          <p class="c1">
            <span class="c9">
              If you choose to remove cookies or reject cookies, this could
              affect certain features or services of our Services. To opt-out of
              interest-based advertising by advertisers on our Services visit{" "}
              <Link
                style={{ color: "blue" }}
                _hover={{
                  textDecoration: "underline",
                }}
                href="http://www.aboutads.info/choices/"
              >
                http://www.aboutads.info/choices/
              </Link>
              .
            </span>
          </p>
          <p class="c7 c5">
            <span class="c6"></span>
          </p>{" "}
          <p class="c7 c5">
            <span class="c6"></span>
          </p>
          <Heading as="h2" fontSize={{ base: "20px", md: "24px" }} mt="8">
            Data retention
          </Heading>
          <p class="c1">
            <span class="c9">
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than the period in which users have an account with us.
            </span>
          </p>
          <p class="c1">
            <span class="c9">
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our Terms of Use and/or comply with applicable legal
              requirements.
            </span>
          </p>
          <p class="c7 c5">
            <span class="c6"></span>
          </p>{" "}
          <p class="c7 c5">
            <span class="c6"></span>
          </p>
          <Heading as="h2" fontSize={{ base: "20px", md: "24px" }} mt="8">
            Data safety
          </Heading>
          <p class="c1">
            <span class="c9">
              We have implemented appropriate technical and organizational
              security measures designed to protect the security of any personal
              information we process. Although we will do our best to protect
              your personal information it is not possible to guarantee a 100%
              protection. Thus, transmission of personal information to and from
              our Services is at your own risk. You should only access the
              Services within a secure environment.
            </span>
          </p>
          <p class="c7 c5">
            <span class="c6"></span>
          </p>{" "}
          <p class="c7 c5">
            <span class="c6"></span>
          </p>
          <Heading as="h2" fontSize={{ base: "20px", md: "24px" }} mt="8">
            Privacy Rights
          </Heading>
          <Text fontWeight="bold">Right to be informed</Text>
          <p class="c1">
            <span class="c9">
              Depending on the circumstances, you have a right to know:
            </span>
          </p>
          <UnorderedList pl="7" class="c2 lst-kix_list_14-0 start">
            <ListItem class="c16 li-bullet-0">
              whether we collect and use your personal information;
            </ListItem>
            <ListItem class="c7 c20 li-bullet-0">
              the categories of personal information that we collect;
            </ListItem>
            <ListItem class="c29 c20 li-bullet-0">
              the purposes for which the collected personal information is used;
            </ListItem>
            <ListItem class="c29 c20 li-bullet-0">
              whether we sell your personal information to third parties;
            </ListItem>{" "}
            <ListItem class="c29 c20 li-bullet-0">
              the categories of personal information that we sold or disclosed
              for a business purpose;
            </ListItem>{" "}
            <ListItem class="c29 c20 li-bullet-0">
              the categories of third parties to whom the personal information
              was sold or disclosed for a business purpose; and
            </ListItem>{" "}
            <ListItem class="c29 c20 li-bullet-0">
              the business or commercial purpose for collecting or selling
              personal information.
            </ListItem>
          </UnorderedList>
          <Text fontWeight="bold">Other privacy rights</Text>
          <UnorderedList pl="6" class="c2 lst-kix_list_14-0 start">
            <ListItem class="c16 li-bullet-0">
              You may object to the processing of your personal information.
            </ListItem>
            <ListItem class="c7 c20 li-bullet-0">
              You may request correction of your personal information if it is
              incorrect or no longer relevant.
            </ListItem>
            <ListItem class="c29 c20 li-bullet-0">
              You may ask to restrict the processing of your personal
              information.
            </ListItem>
            <ListItem class="c29 c20 li-bullet-0">
              You may ask for the deletion of your personal information.
            </ListItem>{" "}
            <ListItem class="c29 c20 li-bullet-0">
              You may ask request the rectification of your personal
              information.
            </ListItem>{" "}
            <ListItem class="c29 c20 li-bullet-0">
              You may request access and obtain a copy of your personal
              information.
            </ListItem>{" "}
          </UnorderedList>
          <p class="c1">
            <span class="c9">
              To exercise these rights, you can contact us by email at{" "}
              <Link
                style={{ color: "blue" }}
                _hover={{
                  textDecoration: "underline",
                }}
                href="mailto:privacy@motarme.com"
              >
                privacy@motarme.com
              </Link>
              .
            </span>
          </p>
          <p class="c1 c5">
            <span class="c9"></span>
          </p>
          <p class="c7 c5">
            <span class="c6"></span>
          </p>
          <p class="c1">
            <Heading as="h2" fontSize={{ base: "20px", md: "24px" }}>
              Controls for Do-Not-Track Features
            </Heading>
          </p>
          <p class="c1">
            <span class="c9">
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track ("DNT") setting you can
              activate to signal your privacy preference not to have data about
              your online browsing activities monitored and collected. Due to no
              uniform technology standard for recognizing and implementing DNT
              signals being finalized, we do not currently respond to DNT
              browser signals or any other mechanism that automatically
              communicates your choice not to be tracked online. If a standard
              for online tracking is adopted that we must follow in the future,
              we will inform you in a revised version of this privacy notice.{" "}
            </span>
          </p>
          <p class="c7 c5">
            <span class="c6"></span>
          </p>{" "}
          <p class="c7 c5">
            <span class="c6"></span>
          </p>
          <Heading as="h2" fontSize={{ base: "20px", md: "24px" }} mt="8">
            Policy Updates
          </Heading>
          <p class="c1">
            <span class="c22 c18 c12">
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated "Revised" date and the
              updated version will be effective as soon as it is accessible.
            </span>
          </p>
          <p class="c7 c5">
            <span class="c6"></span>
          </p>{" "}
          <p class="c7 c5">
            <span class="c6"></span>
          </p>
          <Heading as="h2" fontSize={{ base: "20px", md: "24px" }} mt="8">
            Contact Details
          </Heading>
          <p class="c1">
            <span class="c9">
              If you have questions or comments about this notice, you may email
              us at{" "}
              <Link
                style={{ color: "blue" }}
                _hover={{
                  textDecoration: "underline",
                }}
                href="mailto:privacy@motarme.com"
              >
                privacy@motarme.com
              </Link>{" "}
              or by post to:
            </span>
          </p>
          <p class="c1">
            <span class="c9">Motarme Limited</span>
            <br />

            <span class="c9">Unit 155</span>
            <br />

            <span class="c9">CoLab Business Innovation Centre</span>
            <br />

            <span class="c9">ATU</span>
            <br />

            <span class="c9">Letterkenny</span>
            <br />

            <span class="c9">Donegal F92 PHF4</span>
            <br />

            <span class="c9">Ireland</span>
          </p>
        </Stack>
      </Box>
    </Layout>
  );
};

export default PrivacyPolicyPage;
